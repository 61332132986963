<template>
  <div class="box">
    <div class="bgc"><img src="./../assets/bg.png" alt="" /></div>
    <van-tabs v-model="activeName" type="card" @click="changeTab">
      <van-tab title="进行中" name="inProgress">
        <van-list
          v-model="isUpLoading"
          :finished="upFinished"
          finished-text="没有更多了"
          @load="onLoadList"
          :immediate-check="false"
        >
          <div style="margin-top: 10px">
            <div class="list" v-for="item in list" :key="item.id">
              <div class="info">
                <p class="title">{{ item.title }}</p>
                <p class="time">
                  投票时间：<span
                    >{{ item.start_time }}-{{ item.end_time }}</span
                  >
                </p>
                <p class="num">
                  <span>{{ item.vote_total }}人已投票</span>
                </p>
              </div>
              <div class="vote" @click="goToVote(item.id, item.type)">
                立即投票
              </div>
              <div class="ing"><img src="./../assets/img.png" alt="" /></div>
            </div>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="已结束" name="finish">
        <van-list
          v-model="isUpLoading"
          :finished="upFinished"
          finished-text="没有更多了"
          @load="onLoadList"
          :immediate-check="false"
        >
          <div style="margin-top: 10px">
            <div class="list" v-for="item in listed" :key="item.id">
              <div class="info">
                <p class="title">{{ item.title }}</p>
                <p class="time">
                  投票时间：<span
                    >{{ item.start_time }}-{{ item.end_time }}</span
                  >
                </p>
                <p class="num">
                  <span>{{ item.vote_total }}人已投票</span>
                </p>
              </div>
              <div
                class="voted"
                @click="goToVote(item.id, item.type, item.status)"
              >
                查看详情
              </div>
              <div class="ing"><img src="./../assets/end.png" alt="" /></div>
            </div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { getList } from "@/api/list"; //Login
export default {
  data() {
    return {
      list: [],
      listed: [],
      page: 1,
      num: 10,
      type: 1,
      activeName: "inProgress",
      isUpLoading: false, //上拉加载
      upFinished: false, // 上拉加载完毕
    };
  },
  created() {
    if (!localStorage.getItem("access_id")) {
      this.$router.push("/");
    } else {
      // this.getList();
      this.getroadList();
    }
  },
  methods: {
    goToVote(id, type, status) {
      this.$router.push({ path: "/Detail", query: { id, type, status } });
    },
    changeTab(e) {
      if (e == "inProgress") {
        this.type = 1;
        this.getroadList();
      } else {
        this.type = 2;
        this.getroadList();
      }
    },
    getroadList() {
      // List().then((res) => {
      //   console.log(res);
      // });
      const _self = this;
      // 异步更新数据
      getList({
        type: _self.type,
        page: _self.page,
        pageSize: _self.num,
      })
        .then((res) => {
          if (res.data.code == 200) {
            let rows = res.data.data.list;
            if (rows == null || rows.length === 0) {
              // 加载结束
              _self.upFinished = true;
              return;
            }
            if (rows.length < _self.num) {
              // 最后一页不足10条的情况
              _self.upFinished = true;
            }
            if (_self.page === 1) {
              _self.list = rows;
              if (_self.type == 1) {
                _self.list = rows;
              } else {
                _self.listed = rows;
              }
            } else {
              if (_self.type == 1) {
                _self.list = _self.list.concat(rows);
              } else {
                _self.listed = _self.list.concat(rows);
              }
            }
            console.log(_self.list, "123");
            console.log(_self.listed, "12312");
          } else if (res.data.code == "60003") {
            return this.$toast({ message: res.data.message });
          } else if (res.data.code == "90001" || res.data.code == "1010131") {
            this.$router.push("/");
          } else {
            return this.$toast({ message: res.data.message });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast({
            message: "获取资源列表异常{" + error + "}",
          });
        })
        .finally(() => {
          _self.isUpLoading = false;
        });
    },
    onLoadList() {
      this.page++;
      this.getroadList();
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}
.box {
  height: 100vh;
  background: #225be5;
  overflow: hidden;
  overflow-y: scroll;
  .bgc {
    width: 100%;
    img {
      width: 100%;
    }
  }
}
.list {
  width: 85%;
  // height: 70px;
  background: #fff;
  margin: auto;
  padding: 10px 15px;
  margin-top: 18px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    .title {
      font-size: 16px;
      font-weight: 600;
    }
    .time {
      font-size: 13px;
      color: #666666;
      margin: 6px 0;
    }
    .num {
      background: rgba($color: #ff0000, $alpha: 0.2);
      border-radius: 14px;
      span {
        color: #ff0000;
        font-size: 12px;
        padding: 3px 13px;
      }
    }
  }
}
.ing {
  width: 40px;
  position: absolute;
  top: 0;
  right: 0;
  img {
    width: 100%;
  }
}
.vote {
  min-width: 65px;
  padding: 5px 10px;
  background: linear-gradient(0deg, #015eea 0%, #00c0fa 100%);
  border-radius: 5px;
  color: #fff;
}
.voted {
  min-width: 65px;
  padding: 5px 10px;
  background: #fa9d6f;
  border-radius: 5px;
  color: #fff;
}
.van-tabs /deep/ .van-tabs__nav--card {
  border: none;
  background: #225be5;
  display: flex;
  justify-content: center;
}
.van-tabs /deep/ .van-tab--active {
  background: #fff !important;
  color: #053dc2 !important;
}
.van-tabs /deep/ .van-tab {
  max-width: 85px;
  background: #053dc2;
  color: #ffffff;
  border-radius: 2px;
  border: none;
}
</style>
